html, body, #root {
  height: 100dvh;
  width: 100dvw;
}

.App {
  width: 100%;
  height: 100%;
}

@import './chat-overrides';
@import '../node_modules/@chatscope/chat-ui-kit-styles/themes/default/main';

.cs-message.error .cs-message__content {
  background-color: rgba(200, 0, 0, 0.2);
  border-radius: 0;
  font-weight: bold;
}
