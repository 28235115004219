// Here you can override variables from the imported file above. Check its source for a list of available variables
/*
$main-container-border: 10px;
$color-primary: red;
$color-text: green;
$conversation-color: pink;
*/

@import '../node_modules/@chatscope/chat-ui-kit-styles/themes/default/helpers/functions';

$color-white: #fff !default;
$color-black: #000 !default;

$color-primary: #FB936A;
$color-primary-light: #CFD6D9;
$color-primary-dark: #4D707D;
//$color-primary-light: #FB936A;

$color-text: rgba(255, 255, 255, 0.88); // #212121
$color-text-medium: rgba(255, 255, 255, .60); // #666666
$color-text-disabled: rgba(255, 255, 255, .38); // #9e9e9e
$color-text-inverted: invert($color-text);

$default-bg-color: $color-white;

$default-border-color: rgba-to-rgb(rgba(scale-color($color-primary-dark, $saturation: -55%), 0.4), $default-bg-color);
$chat-container-message-input-border-top: solid 1px $default-border-color;

//$conversation-header-border-bottom: solid 1px rgba-to-rgb(rgba(scale-color($color-primary, $saturation: -55%), 0.4), $default-bg-color);

$conversation-header-bg-color: $color-primary-light !default;
$conversation-header-color: $color-text-inverted !default;
$conversation-header-user-name-color: $conversation-header-color !default;
$conversation-header-user-name-bg-color: $conversation-header-bg-color !default;

$chat-container-color: $color-text !default;
$chat-container-bg-color: $default-bg-color !default;
$chat-container-message-input-color: $color-text !default;
$chat-container-message-input-bg-color: $default-bg-color !default;

$message-input-bg-color: $default-bg-color !default;
$message-input-content-editor-color: $color-text !default;
$message-input-content-editor-wrapper-bg-color: $color-primary-dark !default;
$message-input-content-editor-container-bg-color: $message-input-content-editor-wrapper-bg-color !default;
$message-input-content-editor-bg-color: $message-input-content-editor-wrapper-bg-color !default;
$message-input-content-editor-disabled-color: $color-text-medium !default;
$message-input-content-editor-disabled-bg-color: rgba($message-input-content-editor-bg-color, 0.38) !default;

@import '../node_modules/@chatscope/chat-ui-kit-styles/themes/default/variables';
